import { Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(private readonly layoutService: VexLayoutService) {
    this.loadNavigation();
  }

  loadNavigation(): void {
    this._items.next([
      {
        type: 'subheading',
        label: 'My Space',
        children: [
          {
            type: 'link',
            label: 'Dashboard',
            route: '/home/',
            icon: 'mat:dashboard',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'Organization Chart',
            route: '/home/organizationchart',
            icon: 'mat:account_tree',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'Biometrics',
            route: '/home/biometrics',
            icon: 'mat:fingerprint',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'dropdown',
            label: 'My Profile',
            icon: 'mat:person_outline',
            children: [
              {
                type: 'link',
                label: 'About Me',
                route: '/home/myspace/aboutme',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'Profile',
                route: '/home/myspace/profile',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'Qualification',
                route: '/home/myspace/qualification',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'Experience',
                route: '/home/myspace/experience',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'Trainings',
                route: '/home/myspace/trainings',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'Preferences',
                route: '/home/myspace/preferences',
                routerLinkActiveOptions: { exact: true }
              },
              {
                type: 'link',
                label: 'Change Password',
                route: '/home/myspace/changepassword',
                routerLinkActiveOptions: { exact: true }
              },
            ]
          },
          {
            type: 'link',
            label: 'My Timetable',
            route: '/home/mytimetable',
            icon: 'mat:pending_actions',
            routerLinkActiveOptions: { exact: true }
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Modules',
        children: [
          {
            type: 'dropdown',
            label: 'Administration',
            icon: 'mat:admin_panel_settings',
            children: [
              {
                type: 'link',
                label: 'Entities Setup',
                route: '/home/administration/entity'
              },
              {
                type: 'link',
                label: 'Entity Contacts',
                route: '/home/administration/entitycontacts'
              },
              {
                type: 'link',
                label: 'AY Terms/Semesters',
                route: '/home/administration/ayterms'
              },
              {
                type: 'link',
                label: 'Transport Setup',
                route: '/home/administration/transportsetup'
              },
              {
                type: 'link',
                label: 'Parent Access',
                route: '/home/administration/parentaccess'
              },
              {
                type: 'link',
                label: 'Class Teacher',
                route: '/home/administration/classteachermapping'
              },
              // {
              //   type: 'link',
              //   label: 'Message Template Setup',
              //   route: '/home/administration/messagetemplatesetup'
              // },
              // {
              //   type: 'link',
              //   label: 'School Setup',
              //   route: '/home/apps/contacts/none'
              // },
              {
                type: 'link',
                label: 'Holiday Calendar',
                route: '/home/administration/holidaycalendar'
              },
              // {
              //   type: 'link',
              //   label: 'Event Setup',
              //   route: '/home/administration/eventsetup'
              // },
              {
                type: 'link',
                label: 'Subject Mapping',
                route: '/home/administration/subjectmapping'
              },
              // {
              //   type: 'link',
              //   label: 'Fee Concession Groups',
              //   route: '/home/apps/contacts/none11'
              // },
              // {
              //   type: 'link',
              //   label: 'Email Template Setup',
              //   route: '/home/apps/contacts/none12'
              // }
            ]
          },
          {
            type: 'dropdown',
            label: 'Admissions',
            icon: 'mat:confirmation_number',
            children: [
              {
                type: 'link',
                label: 'New Admissions',
                route: '/home/admissions/newadmissions'
              },
              {
                type: 'link',
                label: 'Payment Collections',
                route: '/home/admissions/paymentcollections'
              },
              // {
              //   type: 'link',
              //   label: 'New Admission Payments',
              //   route: '/home/admissions/newadmissionpayments'
              // },
              {
                type: 'link',
                label: 'Send Admission Form',
                route: '/home/admissions/admissionforms'
              },
              {
                type: 'link',
                label: 'Online Requests',
                route: '/home/admissions/onlinerequests'
              },
              // {
              //   type: 'link',
              //   label: 'Pending Admissions',
              //   route: '/home/admissions/pendingadmissions'
              // }
            ]
          },
          {
            type: 'dropdown',
            label: 'Fee Management',
            icon: 'mat:currency_rupee',
            children: [
              {
                type: 'link',
                label: 'Fee Category',
                route: '/home/feemanagement/feecategory'
              },
              {
                type: 'link',
                label: 'Concession Category',
                route: '/home/feemanagement/concessioncategory'
              },
              {
                type: 'link',
                label: 'Fee Configuration',
                route: '/home/feemanagement/feeconfiguration'
              },
              {
                type: 'link',
                label: 'Bus Fee Configuration',
                route: '/home/feemanagement/busfeeconfiguration'
              },
              {
                type: 'link',
                label: 'Map Fee Structure',
                route: '/home/feemanagement/mapfeestructure'
              },
              {
                type: 'link',
                label: 'Fee Structure Views',
                route: '/home/feemanagement/feestructureview'
              },
              {
                type: 'link',
                label: 'Fee Concession',
                route: '/home/feemanagement/feeconcession'
              },
              {
                type: 'link',
                label: 'Student Specific Fees',
                route: '/home/feemanagement/studentspecificfees'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Students',
            icon: 'mat:diversity_3',
            children: [
              {
                type: 'link',
                label: 'Students Directory',
                icon: 'mat:contacts',
                route: '/home/students/managestudent'
              },
              {
                type: 'link',
                label: 'Class Mapping',
                route: '/home/students/studentmapping'
              },
              {
                type: 'link',
                label: 'Section Change',
                route: '/home/students/studentsectionchange'
              },
              {
                type: 'link',
                label: 'Class-Wise Status',
                route: '/home/students/classwisestatus'
              },
              // {
              //   type: 'link',
              //   label: 'Deactivated',
              //   route: '/home/apps/contacts/none2'
              // },
              // {
              //   type: 'link',
              //   label: 'Alumni',
              //   route: '/home/apps/contacts/none3'
              // },
              // {
              //   type: 'link',
              //   label: 'Update Data',
              //   route: '/home/apps/contacts/none'
              // },
              // {
              //   type: 'link',
              //   label: 'Name (Regional Language)',
              //   route: '/home/apps/contacts/none'
              // },
              // {
              //   type: 'link',
              //   label: 'Class Capacity',
              //   route: '/home/apps/contacts/none4'
              // },
              {
                type: 'link',
                label: 'Strength Analysis',
                route: '/home/students/strengthanalysis'
              },
              // {
              //   type: 'link',
              //   label: 'Class/Section Change',
              //   route: '/home/apps/contacts/none6'
              // },
              // {
              //   type: 'link',
              //   label: 'Class/Section Report',
              //   route: '/home/apps/contacts/none7'
              // }
            ]
          },
          {
            type: 'dropdown',
            label: 'Staff',
            icon: 'mat:supervisor_account',
            children: [
              {
                type: 'link',
                label: 'Manage Staff',
                route: '/home/staff/managestaff'
              },
              // {
              //   type: 'link',
              //   label: 'Contact Update',
              //   route: '/home/apps/contacts/none'
              // },
              {
                type: 'link',
                label: 'Resignations',
                route: '/home/staff/resignation'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Timetable',
            icon: 'mat:event_note',
            children: [
              {
                type: 'link',
                label: 'Timetable Mapping',
                route: '/home/apps/contacts/none8'
              },
              {
                type: 'link',
                label: 'Substitution List',
                route: '/home/apps/contacts/none9'
              },
              {
                type: 'link',
                label: 'Timetable Settings',
                route: '/home/apps/contacts/none10'
              },
              {
                type: 'link',
                label: 'My Timetable',
                route: '/home/apps/contacts/none11'
              },
              {
                type: 'link',
                label: 'Staff Free Period',
                route: '/home/apps/contacts/none12'
              },
              {
                type: 'link',
                label: 'My Substitution',
                route: '/home/apps/contacts/none13'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Communications',
            icon: 'mat:forum',
            children: [
              {
                type: 'link',
                label: 'General Message (WA)',
                route: '/home/communications/generalmessage'
              },
              {
                type: 'link',
                label: 'Student Message History',
                route: '/home/communications/studentwisemessagehistory'
              },
              // {
              //   type: 'link',
              //   label: 'Scheduled Alerts',
              //   route: '/home/apps/contacts/none14'
              // },
              // {
              //   type: 'link',
              //   label: 'Student Remarks',
              //   route: '/home/apps/contacts/none15'
              // },
              // {
              //   type: 'link',
              //   label: 'Direct/Group Message ',
              //   route: '/home/apps/contacts/none17'
              // },
              // {
              //   type: 'link',
              //   label: 'Wishes',
              //   route: '/home/apps/contacts/none18'
              // },
              // {
              //   type: 'link',
              //   label: 'Email Alerts',
              //   route: '/home/apps/contacts/none19'
              // },
              // {
              //   type: 'link',
              //   label: 'Communication Templates',
              //   route: '/home/apps/contacts/none20'
              // },
              // {
              //   type: 'link',
              //   label: 'Alert Settings',
              //   route: '/home/apps/contacts/none21'
              // },
              // {
              //   type: 'link',
              //   label: 'WhatsApp Alerts',
              //   route: '/home/apps/contacts/none22'
              // },
              // {
              //   type: 'link',
              //   label: 'Group Message(WA)',
              //   route: '/home/communications/groupwhatsappmessage'
              // },
            ]
          },
          {
            type: 'dropdown',
            label: 'Attendance',
            icon: 'mat:event_available',
            children: [
              {
                type: 'link',
                label: 'Absent (Student)',
                route: '/home/apps/contacts/none23'
              },
              {
                type: 'link',
                label: 'Late (Student)',
                route: '/home/apps/contacts/none24'
              },
              {
                type: 'link',
                label: 'Absent (Staff)',
                route: '/home/apps/contacts/none25'
              },
              {
                type: 'link',
                label: 'Late (Staff)',
                route: '/home/apps/contacts/none26'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Homework',
            icon: 'mat:home_work',
            children: [
              {
                type: 'link',
                label: 'Homework Dashboard',
                route: '/home/apps/contacts/none27'
              },
              {
                type: 'link',
                label: 'Class Wise Homework',
                route: '/home/homework/classwisehomework'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Exam & Report',
            icon: 'mat:summarize',
            children: [
              {
                type: 'link',
                label: 'Manage Exams',
                route: '/home/apps/contacts/none31'
              },
              {
                type: 'link',
                label: 'Marks Entry',
                route: '/home/apps/contacts/none32'
              },
              {
                type: 'link',
                label: 'Exam Alerts',
                route: '/home/apps/contacts/none33'
              },
              {
                type: 'link',
                label: 'Global Settings',
                route: '/home/apps/contacts/none34'
              },
              {
                type: 'link',
                label: 'Grade Range',
                route: '/home/apps/contacts/none35'
              },
              {
                type: 'link',
                label: 'Co-Sch Subjects',
                route: '/home/apps/contacts/none36'
              },
              {
                type: 'link',
                label: 'Hold Report',
                route: '/home/apps/contacts/none37'
              },
              {
                type: 'link',
                label: 'Co-Scholastic Keys',
                route: '/home/apps/contacts/none38'
              },
              {
                type: 'link',
                label: 'Signature',
                route: '/home/apps/contacts/none39'
              },
              {
                type: 'link',
                label: 'Result Analysis',
                route: '/home/apps/contacts/none40'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Compliances',
            icon: 'mat:gavel',
            children: [
              {
                type: 'link',
                label: 'Transfer Certificate (TC)',
                route: '/home/apps/contacts/none41'
              },
              {
                type: 'link',
                label: 'Bonafide',
                route: '/home/apps/contacts/none42'
              },
              {
                type: 'link',
                label: 'IT Certificate',
                route: '/home/apps/contacts/none43'
              },
              {
                type: 'link',
                label: 'Attendance',
                route: '/home/apps/contacts/none44'
              },
              {
                type: 'link',
                label: 'Study Certificate',
                route: '/home/apps/contacts/none45'
              },
              {
                type: 'link',
                label: 'Conduct Certificate',
                route: '/home/apps/contacts/none46'
              },
              {
                type: 'link',
                label: 'Scholar Report',
                route: '/home/apps/contacts/none47'
              },
              {
                type: 'link',
                label: 'Provisional Certificate',
                route: '/home/apps/contacts/none48'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Biometrics',
            icon: 'mat:fingerprint',
            children: [
              {
                type: 'link',
                label: 'Staff Bio ID',
                route: '/home/apps/contacts/none49'
              },
              {
                type: 'link',
                label: 'Daily Attendance',
                route: '/home/apps/contacts/none50'
              },
              {
                type: 'link',
                label: 'Monthly Attendance',
                route: '/home/apps/contacts/none51'
              },
              {
                type: 'link',
                label: 'Attendance',
                route: '/home/apps/contacts/none52'
              },
              {
                type: 'link',
                label: 'Study Certificate',
                route: '/home/apps/contacts/none53'
              },
              {
                type: 'link',
                label: 'Conduct Certificate',
                route: '/home/apps/contacts/none54'
              },
              {
                type: 'link',
                label: 'Scholar Report',
                route: '/home/apps/contacts/none55'
              },
              {
                type: 'link',
                label: 'Provisional Certificate',
                route: '/home/apps/contacts/none56'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Payroll',
            icon: 'mat:payments',
            children: [
              {
                type: 'link',
                label: 'Salary Components',
                route: '/home/apps/contacts/none57'
              },
              {
                type: 'link',
                label: 'Salary Generation',
                route: '/home/apps/contacts/none58'
              },
              {
                type: 'link',
                label: 'Salary Increment',
                route: '/home/apps/contacts/none59'
              },
              {
                type: 'link',
                label: 'Staff Component',
                route: '/home/apps/contacts/none60'
              },
              {
                type: 'link',
                label: 'Salary Appraisal',
                route: '/home/apps/contacts/none61'
              },
              {
                type: 'link',
                label: 'Arrear Processing',
                route: '/home/apps/contacts/none62'
              },
              {
                type: 'link',
                label: 'Salary Setup',
                route: '/home/apps/contacts/none63'
              },
              {
                type: 'link',
                label: 'Loan Management',
                route: '/home/apps/contacts/none64'
              },
              {
                type: 'link',
                label: 'Leave Management',
                route: '/home/apps/contacts/none65'
              },
              {
                type: 'link',
                label: 'Tax Processing',
                route: '/home/apps/contacts/none66'
              },
              {
                type: 'link',
                label: 'Staff PayCut',
                route: '/home/apps/contacts/none67'
              },
              {
                type: 'link',
                label: 'Leave Request List',
                route: '/home/apps/contacts/none68'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'User Management',
            icon: 'mat:manage_accounts',
            children: [
              {
                type: 'link',
                label: 'User Groups',
                route: '/home/users/usergroup'
              },
              {
                type: 'link',
                label: 'Group Privileges',
                route: '/home/apps/contacts/none69'
              },
              {
                type: 'link',
                label: 'Change Password',
                route: '/home/apps/contacts/none70'
              }
            ]
          },          
          {
            type: 'dropdown',
            label: 'Transportation',
            icon: 'mat:airport_shuttle',
            children: [
              {
                type: 'link',
                label: 'Bus Stop',
                route: '/home/transportation/busstop'
              },
              // {
              //   type: 'link',
              //   label: 'Bus Route',
              //   route: '/home/transportation/busroute'
              // },
              {
                type: 'link',
                label: 'Route-Stop Mapping',
                route: '/home/transportation/routestopmapping'
              },
              {
                type: 'link',
                label: 'Student-Stop Mapping',
                route: '/home/transportation/studentstopmapping'
              },
              // {
              //   type: 'link',
              //   label: 'Bus Fee Settings',
              //   route: '/home/apps/contacts/none71'
              // },
              // {
              //   type: 'link',
              //   label: 'Bus Fee Entry',
              //   route: '/home/apps/contacts/none72'
              // },
              // {
              //   type: 'link',
              //   label: 'Manage Transport Students',
              //   route: '/home/apps/contacts/none73'
              // },
              {
                type: 'link',
                label: 'Vehicle Master',
                route: '/home/transportation/vehicle'
              },
              {
                type: 'link',
                label: 'GPS Registration',
                route: '/home/transportation/gpsregistration'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Masters',
            icon: 'mat:category',
            children: [
              {
                type: 'link',
                label: 'Super Category',
                route: '/home/masters/supercategory'
              },
              {
                type: 'link',
                label: 'Sub Category',
                route: '/home/masters/subcategory'
              },
              {
                type: 'link',
                label: 'Academic Year',
                route: '/home/masters/academicyear'
              },
              {
                type: 'link',
                label: 'Departments',
                route: '/home/masters/department'
              },
              {
                type: 'link',
                label: 'Designation',
                route: '/home/masters/designation'
              },
              {
                type: 'link',
                label: 'Role',
                route: '/home/masters/role'
              },
              // {
              //   type: 'link',
              //   label: 'Class Code',
              //   route: '/home/masters/classcode'
              // },
              {
                type: 'link',
                label: 'Class',
                route: '/home/masters/class'
              },
              {
                type: 'link',
                label: 'Section',
                route: '/home/masters/section'
              },
              {
                type: 'link',
                label: 'Subjects',
                route: '/home/masters/subject'
              },
              {
                type: 'link',
                label: 'Religion',
                route: '/home/masters/religion'
              },
              {
                type: 'link',
                label: 'Community',
                route: '/home/masters/community'
              },
              {
                type: 'link',
                label: 'Bank Master',
                route: '/home/masters/bank'
              },
              {
                type: 'link',
                label: 'House Master',
                route: '/home/masters/house'
              },
              {
                type: 'link',
                label: 'Mother Tongue',
                route: '/home/masters/mothertongue'
              },
              {
                type: 'link',
                label: 'Enquiry Checklist',
                route: '/home/masters/enquirychecklist'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Settings',
            icon: 'mat:settings',
            children: [
              {
                type: 'link',
                label: 'Payment Settings',
                route: '/home/apps/contacts/none75'
              },
              {
                type: 'link',
                label: 'Receipt Mapping',
                route: '/home/apps/contacts/none76'
              },
              {
                type: 'link',
                label: 'Bank Mapping',
                route: '/home/apps/contacts/none77'
              },
              {
                type: 'link',
                label: 'Alert Settings',
                route: '/home/apps/contacts/none78'
              },
              {
                type: 'link',
                label: 'Role Settings',
                route: '/home/apps/contacts/none79'
              }
            ]
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Reports',
        children: [
          {
            type: 'dropdown',
            label: 'Day Wise Reports',
            icon: 'mat:summarize',
            children: [
              {
                type: 'link',
                label: 'Day Dashboard Report',
                route: '/home/reports/daydashboardreport'
              },
              {
                type: 'link',
                label: 'Daily Collection Report',
                route: '/home/reports/dailycollection'
              },
              {
                type: 'link',
                label: 'Day Wise Summary',
                route: '/home/reports/dailysummaryreport'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Student Wise Reports',
            icon: 'mat:summarize',
            children: [
              {
                type: 'link',
                label: 'New Admissions',
                route: '/home/reports/newadmissionsreport'
              },
              {
                type: 'link',
                label: 'Students Report',
                route: '/home/reports/studentsreport'
              },
              {
                type: 'link',
                label: 'Student Defaulters',
                route: '/home/reports/studentdefaultersreport'
              },
              {
                type: 'link',
                label: 'Student Wise Collections',
                route: '/home/reports/studentwisecollectionsreport'
              },
              {
                type: 'link',
                label: 'Category Wise Collections',
                route: '/home/reports/categorywisecollectionsreport'
              },
              {
                type: 'link',
                label: 'Invoice Report',
                route: '/home/reports/invoicereport'
              },
              {
                type: 'link',
                label: 'Concessions Report',
                route: '/home/reports/concessionsreport'
              },
              {
                type: 'link',
                label: 'Student Dropouts',
                route: '/home/reports/studentdropoutsreport'
              },
              // {
              //   type: 'link',
              //   label: 'Invoice Cancellation',
              //   route: '/home/apps/contacts/none88'
              // },
              // {
              //   type: 'link',
              //   label: 'Category Cancellation',
              //   route: '/home/apps/contacts/none89'
              // }
            ]
          },
          {
            type: 'dropdown',
            label: 'Staff Wise Reports',
            icon: 'mat:summarize',
            children: [
              {
                type: 'link',
                label: 'Staff Report',
                route: '/home/reports/staffreport'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Transportation Reports',
            icon: 'mat:summarize',
            children: [
              {
                type: 'link',
                label: 'Bus Fee Report',
                route: '/home/apps/contacts/none92'
              },
              {
                type: 'link',
                label: 'Bus Fee Collection',
                route: '/home/apps/contacts/none93'
              },
              {
                type: 'link',
                label: 'Route Wise Collection Report',
                route: '/home/apps/contacts/none94'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Fee Reports',
            icon: 'mat:summarize',
            children: [
              {
                type: 'link',
                label: 'Fee Collections Report',
                route: '/home/reports/feecollectionsreport'
              },
              // {
              //   type: 'link',
              //   label: 'Class Wise Consolidated',
              //   route: '/home/apps/contacts/none96'
              // },
              // {
              //   type: 'link',
              //   label: 'Student Consolidated',
              //   route: '/home/apps/contacts/none97'
              // },
              // {
              //   type: 'link',
              //   label: 'Student Category Wise',
              //   route: '/home/apps/contacts/none98'
              // },
              // {
              //   type: 'link',
              //   label: 'Category Wise Student Defaulters',
              //   route: '/home/apps/contacts/none99'
              // },
              // {
              //   type: 'link',
              //   label: 'Fee Paid Alerts Report',
              //   route: '/home/apps/contacts/none100'
              // }
            ]
          },
          {
            type: 'dropdown',
            label: 'Online Reports',
            icon: 'mat:summarize',
            children: [
              {
                type: 'link',
                label: 'Online Payments Report',
                route: '/home/apps/contacts/none1'
              },
              {
                type: 'link',
                label: 'Online Success Report',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: 'Online Settlement Report',
                route: '/home/apps/contacts/none'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Communication Reports',
            icon: 'mat:summarize',
            children: [
              {
                type: 'link',
                label: 'Status by Mobile No',
                route: '/home/apps/contacts/none2'
              },
              {
                type: 'link',
                label: 'Status by Message',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: 'Comm Log (Daily)',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: '100% Failure',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: 'Student Failure',
                route: '/home/apps/contacts/none'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Payroll Reports',
            icon: 'mat:summarize',
            children: [
              {
                type: 'link',
                label: 'PF Report',
                route: '/home/apps/contacts/none3'
              },
              {
                type: 'link',
                label: 'ESI Report',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: 'PF Recovery Details',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: 'Salary Register',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: 'Salary Report',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: 'Pay Slip',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: 'Annual Statement',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: 'Consolidated',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: 'Custom Report',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: 'LOP Report',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: 'Form 16',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: 'Remittence',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: 'IT Report',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: 'Annual Report',
                route: '/home/apps/contacts/none'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Attendance Reports',
            icon: 'mat:summarize',
            children: [
              {
                type: 'link',
                label: 'Absent Report',
                route: '/home/apps/contacts/none4'
              },
              {
                type: 'link',
                label: 'Daily Attendance Report',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: 'Monthly Attendance Report',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: 'Staff Wise Attendance Report',
                route: '/home/apps/contacts/none'
              },
              {
                type: 'link',
                label: 'Late Report',
                route: '/home/apps/contacts/none'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Homework Reports',
            icon: 'mat:summarize',
            children: [
              {
                type: 'link',
                label: 'Homework Report',
                route: '/home/apps/contacts/none5'
              }
            ]
          },
          {
            type: 'dropdown',
            label: 'Exams Reports',
            icon: 'mat:summarize',
            children: [
              {
                type: 'link',
                label: 'Student Report',
                route: '/home/apps/contacts/none6'
              },
              {
                type: 'link',
                label: 'Class Report',
                route: '/home/apps/contacts/none'
              }
            ]
          }
        ]
      }
    ]);
  }
}
