export const APIConstants = {
    shared: {
        imageUploader: 'imageuploader/savedocument',
        getCombinedMasters: 'shared/getcombinedmasterslist',
        getDashboardData: 'shared/getdashboarddata'
    },
    user: {
        registerUser: 'users/signup',
        getAllUsers: 'users/getallusers',
        loginUser: 'users/login',
        getUserInfo: 'users/userinfo/',
        createUser: 'users/createuser',
        updateUser: 'users/updateuser',
        changePassword: 'users/changepassword',
        getSchoolStaff: 'users/getschoolstaff/',
        deleteStaff: 'users/',
        saveStaffMigrationImport: 'users/staffmigrationbulkimport',
        updateStaffStatus: 'users/updatestaffstatus',
    },
    academicyear_master: {
        createAcademicYear: 'academicyear/save',
        getByID: 'academicyear/',
        getAcademicYears: 'academicyear/getacademicyears',
        getAllAcademicYears: 'academicyear/getallacademicyears',
        deleteAcademicYear: 'academicyear/',
        updateStatus: 'academicyear/updatestatus'
    },
    department_master: {
        createDepartment: 'department/save',
        getByID: 'department/',
        getDepartments: 'department/getdepartments',
        getAllDepartments: 'department/getalldepartments',
        deleteDepartment: 'department/',
    },
    role_master: {
        createRole: 'role/save',
        getByID: 'role/',
        getRoles: 'role/getroles',
        getAllRoles: 'role/getallroles',
        deleteRole: 'role/',
    },
    classcode_master: {
        createClasscode: 'classcode/save',
        getByID: 'classcode/',
        getClasscodes: 'classcode/getclasscodes',
        getAllClasscodes: 'classcode/getallclasscodes',
        deleteClasscode: 'classcode/',
    },
    studentclass_master: {
        createClass: 'class/save',
        getByID: 'class/',
        getClasses: 'class/getclasses',
        getAllClasses: 'class/getallclasses',
        deleteClass: 'class/',
    },
    designation_master: {
        createDesignation: 'designation/save',
        getByID: 'designation/',
        getDesignations: 'designation/getdesignations',
        getAllDesignations: 'designation/getalldesignations',
        deleteDesignation: 'designation/',
    },
    supercategory_master: {
        createSupercategory: 'supercategory/save',
        getByID: 'supercategory/',
        getSupercategories: 'supercategory/getsupercategories',
        getAllSupercategories: 'supercategory/getallsupercategories',
        deleteSupercategory: 'supercategory/',
    },
    subcategory_master: {
        createSubcategory: 'subcategory/save',
        getByID: 'subcategory/',
        getSubcategories: 'subcategory/getsubcategories',
        getAllSubcategories: 'subcategory/getallsubcategories',
        deleteSubcategory: 'subcategory/',
        getCombinedSubcategoriesLists: 'subcategory/getcombinedlist'
    },
    section_master: {
        createSection: 'section/save',
        getByID: 'section/',
        getSections: 'section/getsections',
        getAllSections: 'section/getallsections',
        deleteSection: 'section/',
    },
    subject_master: {
        createSubject: 'subject/save',
        getByID: 'subject/',
        getSubjects: 'subject/getsubjects',
        getAllSubjects: 'subject/getallsubjects',
        deleteSubject: 'subject/',
    },
    religion_master: {
        createReligion: 'religion/save',
        getByID: 'religion/',
        getReligions: 'religion/getreligions',
        getAllReligions: 'religion/getallreligions',
        deleteReligion: 'religion/',
    },
    community_master: {
        createCommunity: 'community/save',
        getByID: 'community/',
        getCommunities: 'community/getcommunities',
        getAllCommunities: 'community/getallcommunities',
        deleteCommunity: 'community/',
    },
    feecategory_master: {
        createFeecategory: 'feecategory/save',
        getByID: 'feecategory/',
        getFeecategories: 'feecategory/getfeecategories',
        getAllFeecategories: 'feecategory/getallfeecategories',
        deleteFeecategory: 'feecategory/',
    },
    bank_master: {
        createBank: 'bank/save',
        getByID: 'bank/',
        getBanks: 'bank/getbanks',
        getAllBanks: 'bank/getallbanks',
        deleteBank: 'bank/',
    },
    concessioncategory_master: {
        createConcessioncategory: 'concessioncategory/save',
        getByID: 'concessioncategory/',
        getConcessioncategories: 'concessioncategory/getconcessioncategories',
        getAllConcessioncategories: 'concessioncategory/getallconcessioncategories/',
        deleteConcessioncategory: 'concessioncategory/',
    },
    vehicle_master: {
        createVehicle: 'vehicle/save',
        getByID: 'vehicle/',
        getVehicles: 'vehicle/getvehicles',
        getAllVehicles: 'vehicle/getallvehicles',
        deleteVehicle: 'vehicle/',
    },
    house_master: {
        createHouse: 'house/save',
        getByID: 'house/',
        getHouses: 'house/gethouses',
        getAllHouses: 'house/getallhouses',
        deleteHouse: 'house/',
    },
    usergroup_master: {
        createUsergroup: 'usergroup/save',
        getByID: 'usergroup/',
        getUsergroups: 'usergroup/getusergroups',
        getAllUsergroups: 'usergroup/getallusergroups',
        deleteUsergroup: 'usergroup/',
    },
    staff_master: {
        createStaff: 'staff/save',
        getByID: 'staff/',
        getStaffs: 'staff/getstaffs',
        getAllStaffs: 'staff/getallstaffs',
        deleteStaff: 'staff/',
    },
    mothertongue_master: {
        createMothertongue: 'mothertongue/save',
        getByID: 'mothertongue/',
        getMothertongues: 'mothertongue/getmothertongues',
        getAllMothertongues: 'mothertongue/getallmothertongues',
        deleteMothertongue: 'mothertongue/',
    },
    staff_resignations: {
        createResignation: 'resignation/save',
        getByID: 'resignation/',
        getResignations: 'resignation/getresignations',
        getAllResignations: 'resignation/getallresignations',
        deleteResignation: 'resignation/',
    },
    student: {
        getAllStudents: 'student/getallstudents',
        getStudentInfo: 'student/studentinfo/',
        createStudent: 'student/createstudent',
        updateStudentBasicInfo: 'student/updatestudent/basicinfo',
        updateStudentDetails: 'student/updatestudent/detailinfo',
        updateParentDetails: 'student/updatestudent/parentsinfo',
        deleteStudent: 'student/',
        getByID: 'student/',
        getStudentsByEntityAndAY: 'student/getstudentsbyentityanday',
        getClassAndSections: 'student/getclassandsections',
        getUnassignedStudents: 'student/getunassignedstudents/',
        saveStudentMapping: 'student/savestudentmapping',
        getStudentsByClass: 'student/getstudentsbyclass',
        getStudentsByClassForStopMapping: 'student/getstudentsbyclassforstopmapping',
        createAdmissionenquiry: 'student/createadmissionenquiry',
        getAllAdmissionenquiries: 'student/getalladmissionenquiries',
        udpateBusStop: 'student/updatebusstop',
        saveBulkImport: 'student/bulkimport',
        getStudentsByEntityAndClass: 'student/getstudentsbyentityandclass',
        saveStudentMigrationImport: 'student/studentmigrationbulkimport',
        saveStudentMigrationImportUpdateClassSection: 'student/studentmigrationbulkimportupdateclasssection',
        getStudentsByClassForPaymentCollection: 'student/getstudentsbyclassforpaymentcollection',
        getStudentsByClassForMessaging: 'student/getstudentsbyclassformessaging',
        getStudentsAndHWSubjectsByClass: 'student/getstudentsandhwsubjectsbyclass',
        getStudentsByClassAndSection: 'student/getstudentsbyclassandsection',
        getAllConvertedStudents: 'student/getallconvertedstudents',
        updateStudentSection: 'student/updatestudentsection',
        getStudentWiseCollections: 'student/getstudentwisecollections',
        getConcessionsReportData: 'student/getconcessionsreportdata',
        getPaymentReceiptsData: 'student/getpaymentreceiptsdata',
        getClassWiseStatus: 'student/getclasswisestatus',
        getClassStrengthAnalysis: 'student/getclassstrengthanalysis',
        updateStudentStatus: 'student/updatestudentstatus',
        getAllDropoutStudents: 'student/getalldropoutstudents',
        getStudentCategoryWiseCollections: 'student/getstudentcategorywisecollections',
        getStudentCategoryWiseCollectionsByDate: 'student/getstudentcategorywisecollectionsbydate'
    },
    admissionenquiry: {
        createAdmissionenquiry: 'admissionenquiry/save',
        getByID: 'admissionenquiry/',
        getAdmissionenquiries: 'admissionenquiry/getadmissionenquiries',
        getAllAdmissionenquiries: 'admissionenquiry/getalladmissionenquiries',
        deleteAdmissionenquiry: 'admissionenquiry/',
        getNewAdmissionStudentsByClass: 'admissionenquiry/getnewadmissionstudentsbyclass'
    },
    enquirychecklist_master: {
        createEnquirychecklist: 'enquirychecklist/save',
        getByID: 'enquirychecklist/',
        getEnquirychecklists: 'enquirychecklist/getenquirychecklists',
        getAllEnquirychecklists: 'enquirychecklist/getallenquirychecklists',
        deleteEnquirychecklist: 'enquirychecklist/',
    },
    onlinerequest: {
        createOnlinerequest: 'onlinerequest/save',
        getByID: 'onlinerequest/',
        getOnlinerequests: 'onlinerequest/getonlinerequests',
        getAllOnlinerequests: 'onlinerequest/getallonlinerequests',
        deleteOnlinerequest: 'onlinerequest/',
    },
    admissionform: {
        createAdmissionform: 'admissionform/save',
        getByID: 'admissionform/',
        getAdmissionforms: 'admissionform/getadmissionforms',
        getAllAdmissionforms: 'admissionform/getalladmissionforms',
        deleteAdmissionform: 'admissionform/',
    },
    pendingadmission: {
        createPendingadmission: 'pendingadmission/save',
        getByID: 'pendingadmission/',
        getPendingadmissions: 'pendingadmission/getpendingadmissions',
        getAllPendingadmissions: 'pendingadmission/getallpendingadmissions',
        deletePendingadmission: 'pendingadmission/',
    },
    entitiessetup: {
        createEntitiessetup: 'entitiessetup/save',
        getByID: 'entitiessetup/',
        getEntitiessetup: 'entitiessetup/getentitiessetup',
        getAllEntitiessetup: 'entitiessetup/getallentitiessetup',
        deleteEntitiessetup: 'entitiessetup/',
        updateLogoPath: 'entitiessetup/updatelogopath'
    },
    entitycontacts: {
        saveEntityContact: 'entitycontact/save',
        getEntityContacts: 'entitycontact/getentitycontacts/',
        deleteEntityContact: 'entitycontact/',
    },
    mapfeestructure: {
        checkIfFeeCombinationExists: 'feemapping/checkifcombinationexists',
        saveFeeMapping: 'feemapping/save',
        deleteFeeMapping: 'feemapping/',
        getFeeStructures: 'feemapping/getfeestructures',
    },
    feeconfiguration: {
        saveFeeConfiguration: 'feeconfiguration/save',
        getFeeConfigurationsBySelection: 'feeconfiguration/getfeeconfigurationsbyselection/',
        getFeeConfigurationsByClass: 'feeconfiguration/getfeeconfigurationsbyclass/',
        deleteFeeConfiguration: 'feeconfiguration/',
        getActiveFeeConfigurationsByClass: 'feeconfiguration/getactivefeeconfigurationsbyclass/'        
    },
    ayterms: {
        saveAyTerm: 'term/save',
        getAyTerms: 'term/getterms/',
        deleteAyTerm: 'term/',
    },
    feeconcession: {
        saveFeeConcession: 'feeconcession/save',
        getFeeConcessions: 'feeconcession/getfeeconcessions',
    },
    studentspecificfees: {
        saveFeeConcession: 'feeconcession/save',
        getFeeConcessions: 'feeconcession/getfeeconcessions',
    },
    feemaster: {
        saveFeeMaster: 'feemaster/save',
        getFeePublishStatus: 'feemaster/getfeepublishstatus',
        getFeemasterByStudentTypeAndClass: 'feemaster/getfeemasterbystudenttypeandclass'
    },
    feereceipt: {
        getFeeReceiptsByStudent: 'feereceipt/getfeereceiptsbystudent',
        saveStudentSpecificFee: 'feereceipt/saveonetimestudentspecific',
        saveUpdateFeeStatusByStudent: 'feereceipt/saveupdatefeestatusbystudentv4',
        saveStudentSpecificTransportFee: 'feereceipt/savestudentspecifictransportfee',
        getStudentReceiptPrintData: 'feereceipt/getstudentreceiptprintdata/',      
        getStudentPaymentHistory: 'feereceipt/getstudentpaymenthistory'
    },
    busstop_master: {
        createBusstop: 'busstop/save',
        getByID: 'busstop/',
        getBusstops: 'busstop/getbusstops',
        getAllBusstops: 'busstop/getallbusstops',
        deleteBusstop: 'busstop/',
    },
    transportsetup: {
        saveTransportSetup: 'transportsetup/save',
        getTransportSetups: 'transportsetup/gettransportsetups/',
        deleteTransportSetup: 'transportsetup/',
        getTransportSetupByAY: 'transportsetup/gettransportsetupsbyay/'
    },
    busfeeconfiguration: {
        saveBusFeeConfiguration: 'busfeeconfiguration/save',
        getBusFeeConfigurationsBySelection: 'busfeeconfiguration/getbusfeeconfigurationsbyselection/',
        deleteBusFeeConfiguration: 'busfeeconfiguration/',
    },
    studentstopmapping: {
        saveStudentStopMapping: 'student/savestudentstopmapping'
    },
    busroute_master: {
        createBusroute: 'busroute/save',
        getRoutesByEntityAY: 'busroute/getroutesbyentityay',
        saveRouteMapping: 'busroute/saveroutemapping',
        getByID: 'busroute/',
        getAllBusroutes: 'busroute/getallbusroutes',
        deleteBusroute: 'busroute/',
    },
    whatsapp_group_communications: {
        saveGroup: 'whatsappgroup/save',
        saveImageAndMessage: 'whatsappgroup/saveimageandmessagenew',
        getallwhatsappgroups: 'whatsappgroup/getallwhatsappgroups',
        getwhatsappgroupssbyid: 'whatsappgroup/',
        deletewhatsappgroup: 'whatsappgroup/',
        getAllWhatsAppMessageHistory: 'whatsappgroupmessagehistory/getallmessagehistory',
        addMemberToGroup: 'whatsappgroup/addmembertogroup',
        sendMessageToSelectedMembers: 'whatsappgroup/sendmessagetoselectedmembers',
        getMessageStatusFromUltramsg: 'whatsappgroup/getmessagestatusfromultramsg',
        sendHomeworkMessageToSelectedClass: 'whatsappgroup/sendhomeworkmessagetoselectedclass'
    },
    student_arrears: {
        getStudentOutstandingDues: 'studentarrears/getstudentoutstandingdues',
        getStudentPastDues: 'studentdefaulters/getdefaultslistbystudent'
    },
    reports: {
        getDailyCollectionReportData: 'reports/getdailycollectionreportdata',
        getNewAdmissionsReportData: 'reports/getnewadmissionsreportdata',
        getDayWiseSummaryReportData: 'reports/getdaywisesummaryreportdata',
        getStudentDefaultersReportData: 'reports/getstudentdefaultersreportdata',
        saveStudentDefaultersImport: 'studentdefaulters/bulkdefaultersimport'
    },
    gpstrackerregistration: {
        createGpsregistration: 'gpstrackerregistration/save',
        getByID: 'gpstrackerregistration/',
        getAllGpsregistrations: 'gpstrackerregistration/getallgpsregistrations',
        deleteGpsregistration: 'gpstrackerregistration/',
    },
    parents_access: {
        getAllParentsAccess: 'parentdetail/getallparentdetails',
        createParentAccess: 'parentdetail/createparentlogin',
        updateParentAccess: 'parentdetail/updateparentdetail',
        getByID: 'parentdetail/',
        getParentDetailInfo: 'parentdetail/parentdetailinfo/'
    },
    class_teacher_mapping: {
        getAllClassTeachers: 'classteachermapping/getclassteacherlist',
        saveClassTeacher: 'classteachermapping/save'
    },
    holiday_calendar: {
        saveHoliday: 'holidaycalendar/save',
        getHolidaysByEntityAY: 'holidaycalendar/getholidaysbyentityay',
        getByID: 'holidaycalendar/',
        deleteHoliday: 'holidaycalendar/',
        deleteHolidayCalendar: 'holidaycalendar/',
    },
    subject_mapping: {
        createSubjectMapping: 'subjectmapping/save',
        updateSubjectMapping: 'subjectmapping/update',
        getSubjectMappingsByEntity: 'subjectmapping/getsubjectmappingsbyentity'
    }
}