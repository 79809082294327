<div class="body-2 p-6 bg-primary-600 text-on-primary-600">
  <p>Today</p>
  <div class="display-1">{{ dayName }}</div>
  <div class="display-1">{{ date }}</div>
</div>

<mat-divider></mat-divider>

<mat-nav-list>
  <h3 matSubheader>UPCOMING EVENTS</h3>
  <a [routerLink]="[]" mat-list-item>
    <h4 matListItemTitle>Teacher's Meeting</h4>
    <p matListItemLine>In 16 Minutes, Staff Room</p>
  </a>
  <a [routerLink]="[]" mat-list-item>
    <h4 matListItemTitle>Meeting with Principal</h4>
    <p matListItemLine>2:00 PM</p>
  </a>
  <mat-divider></mat-divider>
  <h3 matSubheader>TODO-LIST</h3>
  <a [routerLink]="[]" mat-list-item>
    <h4 matListItemTitle>Talk to Maths teacher</h4>
    <p matListItemLine>Added: 6 hours ago</p>
  </a>
  <a [routerLink]="[]" mat-list-item>
    <h4 matListItemTitle>Complete Admission Formalities</h4>
    <p matListItemLine>Added: 2 days ago</p>
  </a>
  <a [routerLink]="[]" mat-list-item>
    <h4 matListItemTitle>Apply for Leave</h4>
    <p matListItemLine>Added: 5 days ago</p>
  </a>
</mat-nav-list>
