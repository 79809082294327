export const environment = {
    //base_url: 'http://localhost:8080/api/',
    base_url: 'https://myrainbowapp.info/api/',
    years: ['2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034', '2035'],
    titles: ['Mr.', 'Ms.', 'Mrs.', 'Miss.', 'Master.', 'Rev.Sr.', 'Rev.Fr', 'Sr.', 'Fr.', 'Bro.', 'Dr.', 'Prof.', 'Sri.', 'Smt.', 'Kum.'],
    blood_groups: ['A +ve', 'A -ve', 'B +ve', 'B -ve', 'O +ve', 'O -ve', 'AB +ve', 'AB -ve']
};

export const super_categories = {
    titles: 'TITL',
    blood_group: 'BLGR',
    role_type: 'ROTY',
    standard_classification: 'STCL',
    vehicle: 'VEHC',
    resignation_reasons: 'RERE',
    relationship_with_student: 'REWT',
    payment_modes: 'PAMO'
}

export const masters = {
    roles: 'ROLE',
    departments: 'DEPT',
    designations: 'DESG',
    class: 'CLAS',
    sections: 'SECT',
    subjects: 'SUBJ',
    religion: 'RELG',
    community: 'COMT',
    academic_year: 'ACYE',
    fee_category: 'FECA',
    bank_master: 'BAMA',
    concession_category: 'COCA',
    vehicle: 'VEHI',
    house_master: 'HOMA',
    mother_tongue: 'MOTO',
    enquiry_checklist: 'ENCH',
    entity: 'ENTI',
    user: 'USER',
    term: 'TERM',
    bus_stop: 'BUST',
    bus_route: 'BURO'
}

export const app_settings = {
    message_display_timer: 3000
}

export const months = [
    { name: 'January', value: 0, shortname: 'Jan' },
    { name: 'February', value: 1, shortname: 'Feb' },
    { name: 'March', value: 2, shortname: 'Mar' },
    { name: 'April', value: 3, shortname: 'Apr' },
    { name: 'May', value: 4, shortname: 'May' },
    { name: 'June', value: 5, shortname: 'Jun' },
    { name: 'July', value: 6, shortname: 'Jul' },
    { name: 'August', value: 7, shortname: 'Aug' },
    { name: 'September', value: 8, shortname: 'Sep' },
    { name: 'October', value: 9, shortname: 'Oct' },
    { name: 'November', value: 10, shortname: 'Nov' },
    { name: 'December', value: 11, shortname: 'Dec' }
  ]

  export const hours = [
    { name: '01' },
    { name: '02' },
    { name: '03' },
    { name: '04' },
    { name: '05' },
    { name: '06' },
    { name: '07' },
    { name: '08' },
    { name: '09' },
    { name: '10' },
    { name: '11' },
    { name: '12' }
  ]

  export const minutes = [
    { name: '00' },
    { name: '01' },
    { name: '02' },
    { name: '03' },
    { name: '04' },
    { name: '05' },
    { name: '06' },
    { name: '07' },
    { name: '08' },
    { name: '09' },
    { name: '10' },
    { name: '11' },
    { name: '12' },
    { name: '13' },
    { name: '14' },
    { name: '15' },
    { name: '16' },
    { name: '17' },
    { name: '18' },
    { name: '19' },
    { name: '20' },
    { name: '21' },
    { name: '22' },
    { name: '23' },
    { name: '24' },
    { name: '25' },
    { name: '26' },
    { name: '27' },
    { name: '28' },
    { name: '29' },
    { name: '30' },
    { name: '31' },
    { name: '32' },
    { name: '33' },
    { name: '34' },
    { name: '35' },
    { name: '36' },
    { name: '37' },
    { name: '38' },
    { name: '39' },
    { name: '40' },
    { name: '41' },
    { name: '42' },
    { name: '43' },
    { name: '44' },
    { name: '45' },
    { name: '46' },
    { name: '47' },
    { name: '48' },
    { name: '49' },
    { name: '50' },
    { name: '51' },
    { name: '52' },
    { name: '53' },
    { name: '54' },
    { name: '55' },
    { name: '56' },
    { name: '57' },
    { name: '58' },
    { name: '59' }
  ]
