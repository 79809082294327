import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';

export const appRoutes: VexRoutes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/auth/login/login.component').then(
        (m) => m.LoginComponent
      )
  },
  {
    path: 'register',
    loadComponent: () =>
      import('./pages/auth/register/register.component').then(
        (m) => m.RegisterComponent
      )
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import(
        './pages/auth/forgot-password/forgot-password.component'
      ).then((m) => m.ForgotPasswordComponent)
  },
  {
    path: 'coming-soon',
    loadComponent: () =>
      import('./pages/shared/coming-soon/coming-soon.component').then(
        (m) => m.ComingSoonComponent
      )
  },
  {
    path: 'home',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            './pages/dashboards/dashboard-analytics/dashboard-analytics.component'
          ).then((m) => m.DashboardAnalyticsComponent)
      },
      {
        path: 'organizationchart',
        loadComponent: () =>
          import(
            './pages/dashboards/organizationchart/organizationchart.component'
          ).then((m) => m.OrganizationchartComponent)
      },
      {
        path: 'biometrics',
        loadComponent: () =>
          import(
            './pages/dashboards/biometrics/biometrics.component'
          ).then((m) => m.BiometricsComponent)
      },
      {
        path: 'mytimetable',
        loadComponent: () =>
          import(
            './pages/dashboards/mytimetable/mytimetable.component'
          ).then((m) => m.MytimetableComponent)
      },
      {
        path: 'myspace',
        loadChildren: () => import('./pages/myspace/myprofile/profile.routes')
      },
      {
        path: 'administration',
        children: [
          {
            path: 'entity',
            loadComponent: () =>
              import('./pages/administration/entity/entity.component').then(
                (m) => m.EntityComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'entitycontacts',
            loadComponent: () =>
              import('./pages/administration/entitycontacts/entitycontacts.component').then(
                (m) => m.EntitycontactsComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'ayterms',
            loadComponent: () =>
              import('./pages/administration/ayterms/ayterms.component').then(
                (m) => m.AytermsComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'transportsetup',
            loadComponent: () =>
              import('./pages/administration/transportsetup/transportsetup.component').then(
                (m) => m.TransportsetupComponent
              ),
            data: {
              toolbarShadowEnabled: false,
            }
          },
          {
            path: 'parentaccess',
            loadComponent: () =>
              import('./pages/administration/parentaccess/parentaccess.component').then(
                (m) => m.ParentaccessComponent
              ),
            data: {
              toolbarShadowEnabled: false,
            }
          },
          {
            path: 'classteachermapping',
            loadComponent: () =>
              import('./pages/administration/classteachermapping/classteachermapping.component').then(
                (m) => m.ClassteachermappingComponent
              ),
            data: {
              toolbarShadowEnabled: false,
            }
          },
          {
            path: 'messagetemplatesetup',
            loadComponent: () =>
              import('./pages/administration/messagetemplatesetup/messagetemplatesetup.component').then(
                (m) => m.MessagetemplatesetupComponent
              ),
            data: {
              toolbarShadowEnabled: false,
            }
          },
          {
            path: 'holidaycalendar',
            loadComponent: () =>
              import('./pages/administration/holidaycalendar/holidaycalendar.component').then(
                (m) => m.HolidaycalendarComponent
              ),
            data: {
              toolbarShadowEnabled: false,
            }
          },
          {
            path: 'eventsetup',
            loadComponent: () =>
              import('./pages/administration/eventsetup/eventsetup.component').then(
                (m) => m.EventsetupComponent
              ),
            data: {
              toolbarShadowEnabled: false,
            }
          },
          {
            path: 'subjectmapping',
            loadComponent: () =>
              import('./pages/administration/subjectmapping/subjectmapping.component').then(
                (m) => m.SubjectmappingComponent
              ),
            data: {
              toolbarShadowEnabled: false,
            }
          },
        ]
      },
      {
        path: 'admissions',
        children: [
          {
            path: 'newadmissions',
            loadComponent: () =>
              import('./pages/admissions/newadmissions/newadmissions.component').then(
                (m) => m.NewadmissionsComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'newadmissionpayments',
            loadComponent: () =>
              import('./pages/admissions/newadmissionpayments/newadmissionpayments.component').then(
                (m) => m.NewadmissionpaymentsComponent
              ),
            data: {
              toolbarShadowEnabled: false,
              scrollDisabled: true
            }
          },
          {
            path: 'admissionforms',
            loadComponent: () =>
              import('./pages/admissions/admissionforms/admissionforms.component').then(
                (m) => m.AdmissionformsComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'onlinerequests',
            loadComponent: () =>
              import('./pages/admissions/onlinerequests/onlinerequests.component').then(
                (m) => m.OnlinerequestsComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'pendingadmissions',
            loadComponent: () =>
              import('./pages/admissions/pendingadmissions/pendingadmissions.component').then(
                (m) => m.PendingadmissionsComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'paymentcollections',
            loadComponent: () =>
              import('./pages/admissions/paymentcollections/paymentcollections.component').then(
                (m) => m.PaymentcollectionsComponent
              ),
            data: {
              toolbarShadowEnabled: false,
              scrollDisabled: true
            }
          }
        ]
      },
      {
        path: 'feemanagement',
        children: [
          {
            path: 'feecategory',
            loadComponent: () =>
              import('./pages/feemanagement/feecategorysetup/feecategorysetup.component').then(
                (m) => m.FeecategorysetupComponent
              ),
            data: {
              toolbarShadowEnabled: false,
              scrollDisabled: true
            }
          },
          {
            path: 'concessioncategory',
            loadComponent: () =>
              import('./pages/feemanagement/concessionsetup/concessionsetup.component').then(
                (m) => m.ConcessionsetupComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'feeconfiguration',
            loadComponent: () =>
              import('./pages/feemanagement/feeconfiguration/feeconfiguration.component').then(
                (m) => m.FeeconfigurationComponent
              ),
            data: {
              toolbarShadowEnabled: false,
            }
          },
          {
            path: 'busfeeconfiguration',
            loadComponent: () =>
              import('./pages/feemanagement/busfeeconfiguration/busfeeconfiguration.component').then(
                (m) => m.BusfeeconfigurationComponent
              ),
            data: {
              toolbarShadowEnabled: false,
            }
          },
          {
            path: 'mapfeestructure',
            loadChildren: () =>
              import('./pages/feemanagement/mapfeestructure/mapfeestructure.routes'),
            data: {
              toolbarShadowEnabled: false
            }
            // loadComponent: () =>
            //   import('./pages/feemanagement/mapfeestructure/mapfeestructure.component').then(
            //     (m) => m.MapfeestructureComponent
            //   ),

            // loadChildren: () =>
            //   import('./pages/apps/scrumboard/scrumboard.routes'),
            // data: {
            //   toolbarShadowEnabled: false
            // }
          },
          {
            path: 'feestructureview',
            loadChildren: () =>
              import('./pages/feemanagement/feestructureview/feestructureview.routes'),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'feeconcession',
            loadComponent: () =>
              import('./pages/feemanagement/feeconcession/feeconcession.component').then(
                (m) => m.FeeconcessionComponent
              ),
            data: {
              toolbarShadowEnabled: false,
              scrollDisabled: true
            }
          },
          {
            path: 'studentspecificfees',
            loadComponent: () =>
              import('./pages/feemanagement/studentspecificfees/studentspecificfees.component').then(
                (m) => m.StudentspecificfeesComponent
              ),
            data: {
              toolbarShadowEnabled: false,
              scrollDisabled: true
            }
          }
        ]
      },
      {
        path: 'students',
        children: [
          // {
          //   path: 'admissionenquiry',
          //   loadComponent: () =>
          //     import('./pages/students/admissionenquiry/admissionenquiry.component').then(
          //       (m) => m.AdmissionenquiryComponent
          //     ),
          //   data: {
          //     toolbarShadowEnabled: false
          //   }
          // },
          {
            path: 'managestudent',
            loadComponent: () =>
              import('./pages/students/managestudent/managestudent.component').then(
                (m) => m.ManagestudentComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'studentmapping',
            loadComponent: () =>
              import('./pages/students/studentmapping/studentmapping.component').then(
                (m) => m.StudentmappingComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'studentsectionchange',
            loadComponent: () =>
              import('./pages/students/studentsectionchange/studentsectionchange.component').then(
                (m) => m.StudentsectionchangeComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'classwisestatus',
            loadComponent: () =>
              import('./pages/students/classwisestatus/classwisestatus.component').then(
                (m) => m.ClasswisestatusComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'strengthanalysis',
            loadComponent: () =>
              import('./pages/students/strengthanalysis/strengthanalysis.component').then(
                (m) => m.StrengthanalysisComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          }
        ]
      },
      {
        path: 'staff',
        children: [
          {
            path: 'newstaff',
            loadComponent: () =>
              import('./pages/staff/newstaff/newstaff.component').then(
                (m) => m.NewstaffComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'managestaff',
            loadComponent: () =>
              import('./pages/staff/managestaff/managestaff.component').then(
                (m) => m.ManagestaffComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'resignation',
            loadComponent: () =>
              import('./pages/staff/resignation/resignation.component').then(
                (m) => m.ResignationComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
        ]
      },
      {
        path: 'masters',
        children: [
          {
            path: 'supercategory',
            loadComponent: () =>
              import('./pages/masters/supercategory/supercategory.component').then(
                (m) => m.SupercategoryComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'subcategory',
            loadComponent: () =>
              import('./pages/masters/subcategory/subcategory.component').then(
                (m) => m.SubcategoryComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'academicyear',
            loadComponent: () =>
              import('./pages/masters/academicyear/academicyear.component').then(
                (m) => m.AcademicyearComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'department',
            loadComponent: () =>
              import('./pages/masters/department/department.component').then(
                (m) => m.DepartmentComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'designation',
            loadComponent: () =>
              import('./pages/masters/designation/designation.component').then(
                (m) => m.DesignationComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'role',
            loadComponent: () =>
              import('./pages/masters/role/role.component').then(
                (m) => m.RoleComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          // {
          //   path: 'classcode',
          //   loadComponent: () =>
          //     import('./pages/masters/classcode/classcode.component').then(
          //       (m) => m.ClasscodeComponent
          //     ),
          //   data: {
          //     toolbarShadowEnabled: false
          //   }
          // },
          {
            path: 'class',
            loadComponent: () =>
              import('./pages/masters/studentclass/studentclass.component').then(
                (m) => m.StudentclassComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'section',
            loadComponent: () =>
              import('./pages/masters/section/section.component').then(
                (m) => m.SectionComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'subject',
            loadComponent: () =>
              import('./pages/masters/subject/subject.component').then(
                (m) => m.SubjectComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'religion',
            loadComponent: () =>
              import('./pages/masters/religion/religion.component').then(
                (m) => m.ReligionComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'community',
            loadComponent: () =>
              import('./pages/masters/community/community.component').then(
                (m) => m.CommunityComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'bank',
            loadComponent: () =>
              import('./pages/masters/bank/bank.component').then(
                (m) => m.BankComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'house',
            loadComponent: () =>
              import('./pages/masters/house/house.component').then(
                (m) => m.HouseComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'mothertongue',
            loadComponent: () =>
              import('./pages/masters/mothertongue/mothertongue.component').then(
                (m) => m.MothertongueComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'enquirychecklist',
            loadComponent: () =>
              import('./pages/masters/enquirychecklist/enquirychecklist.component').then(
                (m) => m.EnquirychecklistComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          }
        ]
      },
      {
        path: 'communications',
        children: [
          {
            path: 'groupwhatsappmessage',
            loadComponent: () =>
              import('./pages/communications/groupwhatsappmessage/groupwhatsappmessage.component').then(
                (m) => m.GroupwhatsappmessageComponent
              ),
            data: {
              toolbarShadowEnabled: false,
              scrollDisabled: true
            }
          },
          {
            path: 'generalmessage',
            loadComponent: () =>
              import('./pages/communications/generalmessage/generalmessage.component').then(
                (m) => m.GeneralmessageComponent
              ),
            data: {
              toolbarShadowEnabled: false,
              scrollDisabled: true
            }
          },
          {
            path: 'studentwisemessagehistory',
            loadComponent: () =>
              import('./pages/communications/studentwisemessagehistory/studentwisemessagehistory.component').then(
                (m) => m.StudentwisemessagehistoryComponent
              ),
            data: {
              toolbarShadowEnabled: false,
              scrollDisabled: true
            }
          }
        ]
      },
      {
        path: 'homework',
        children: [
          {
            path: 'classwisehomework',
            loadComponent: () =>
              import('./pages/homework/classwisehomework/classwisehomework.component').then(
                (m) => m.ClasswisehomeworkComponent
              ),
            data: {
              toolbarShadowEnabled: false,
              scrollDisabled: true
            }
          }
        ]
      },
      {
        path: 'users',
        children: [
          {
            path: 'usergroup',
            loadComponent: () =>
              import('./pages/usermanagement/usergroup/usergroup.component').then(
                (m) => m.UsergroupComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
        ]
      },
      {
        path: 'transportation',
        children: [
          {
            path: 'busstop',
            loadComponent: () =>
              import('./pages/transportation/busstop/busstop.component').then(
                (m) => m.BusstopComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'busroute',
            loadComponent: () =>
              import('./pages/transportation/busroute/busroute.component').then(
                (m) => m.BusrouteComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'routestopmapping',
            loadComponent: () =>
              import('./pages/transportation/routestopmapping/routestopmapping.component').then(
                (m) => m.RoutestopmappingComponent
              ),
            data: {
              toolbarShadowEnabled: false,
            }
          },
          {
            path: 'studentstopmapping',
            loadComponent: () =>
              import('./pages/transportation/studentstopmapping/studentstopmapping.component').then(
                (m) => m.StudentstopmappingComponent
              ),
            data: {
              toolbarShadowEnabled: false,
            }
          },
          {
            path: 'vehicle',
            loadComponent: () =>
              import('./pages/masters/vehicle/vehicle.component').then(
                (m) => m.VehicleComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'gpsregistration',
            loadComponent: () =>
              import('./pages/transportation/gpsregistration/gpsregistration.component').then(
                (m) => m.GpsregistrationComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          }
        ]
      },
      {
        path: 'reports',
        children: [
          {
            path: 'dailycollection',
            loadComponent: () =>
              import('./pages/reports/dailycollectionreport/dailycollectionreport.component').then(
                (m) => m.DailycollectionreportComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'newadmissionsreport',
            loadComponent: () =>
              import('./pages/reports/newadmissionsreport/newadmissionsreport.component').then(
                (m) => m.NewadmissionsreportComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'studentsreport',
            loadComponent: () =>
              import('./pages/reports/studentsreport/studentsreport.component').then(
                (m) => m.StudentsreportComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'studentdropoutsreport',
            loadComponent: () =>
              import('./pages/reports/studentdropoutsreport/studentdropoutsreport.component').then(
                (m) => m.StudentdropoutsreportComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'dailysummaryreport',
            loadComponent: () =>
              import('./pages/reports/dailysummaryreport/dailysummaryreport.component').then(
                (m) => m.DailysummaryreportComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'daydashboardreport',
            loadComponent: () =>
              import('./pages/reports/daydashboardreport/daydashboardreport.component').then(
                (m) => m.DaydashboardreportComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'feecollectionsreport',
            loadComponent: () =>
              import('./pages/reports/feecollectionsreport/feecollectionsreport.component').then(
                (m) => m.FeecollectionsreportComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'studentdefaultersreport',
            loadComponent: () =>
              import('./pages/reports/studentdefaultersreport/studentdefaultersreport.component').then(
                (m) => m.StudentdefaultersreportComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'staffreport',
            loadComponent: () =>
              import('./pages/reports/staffreport/staffreport.component').then(
                (m) => m.StaffreportComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'studentwisecollectionsreport',
            loadComponent: () =>
              import('./pages/reports/studentwisecollectionsreport/studentwisecollectionsreport.component').then(
                (m) => m.StudentwisecollectionsreportComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'categorywisecollectionsreport',
            loadComponent: () =>
              import('./pages/reports/categorywisecollectionsreport/categorywisecollectionsreport.component').then(
                (m) => m.CategorywisecollectionsreportComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'concessionsreport',
            loadComponent: () =>
              import('./pages/reports/concessionsreport/concessionsreport.component').then(
                (m) => m.ConcessionsreportComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
          {
            path: 'invoicereport',
            loadComponent: () =>
              import('./pages/reports/invoicereport/invoicereport.component').then(
                (m) => m.InvoicereportComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          }
        ]
      },
      {
        path: 'apps',
        children: [
          {
            path: 'contacts',
            loadChildren: () => import('./pages/apps/contacts/contacts.routes')
          },
          {
            path: 'aio-table',
            loadComponent: () =>
              import('./pages/apps/aio-table/aio-table.component').then(
                (m) => m.AioTableComponent
              ),
            data: {
              toolbarShadowEnabled: false
            }
          },
        ]
      },
      {
        path: '**',
        loadComponent: () =>
          import('./pages/shared/errors/error-404/error-404.component').then(
            (m) => m.Error404Component
          )
      }
    ]
  }
];
