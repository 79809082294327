import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { MasterService } from 'src/app/core/services/master.service';
import { SharedService } from '../shared/shared.service';
import { environment } from 'src/environments/environment';
import { APIConstants } from 'src/app/core/constants/APIConstants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token!: string;
  private isAuthenticated = false;
  private tokenTimer: any;
  private userId!: string;
  private authStatusListener = new Subject<boolean>();

  constructor(
    private master: MasterService,
    private router: Router,
    private sharedService: SharedService
  ) { }

  getToken() {
    return this.token;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getUserId() {
    return this.userId;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  registerUser(full_name: string, email: string, password: string) {
    const authData: any = { full_name: full_name, email: email, password: password };

    return this.master.post(environment.base_url + APIConstants.user.registerUser, authData)
      .subscribe({
        next: (res: any) => {
          this.login(email, password);
        },
        error: (err: any) => {
          this.authStatusListener.next(false);
        },
        complete: () => {
          //we will enter here after successful response and processing
        }
      })
  }

  login(email: string, password: string) {
    const authData: any = { email: email, password: password };
    this.master.post(environment.base_url + APIConstants.user.loginUser, authData)
      .subscribe({
        next: (response: any) => {
          const token = response.token;
          this.token = token;
          if (token) {
            const expiresInDuration = response.expiresIn;
            this.setAuthTimer(expiresInDuration);
            this.isAuthenticated = true;
            this.userId = response.userId;
            this.authStatusListener.next(true);
            const now = new Date();
            const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
            this.saveAuthData(token, expirationDate, this.userId, response.user);
            this.router.navigate(['/', 'home']);
          }
        },
        error: (err: any) => {
          this.authStatusListener.next(false);
        },
        complete: () => {
          //we will enter here after successful response and processing
        }
      });
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      this.logout();
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.userId = authInformation.userId || '';
      this.sharedService.setUserId(authInformation.userId || '');
      this.sharedService.setUsername(authInformation.userName || '');
      this.sharedService.setUserPhoto(authInformation.userPhoto || '');
      this.sharedService.setAccountId(authInformation.accountId || '');
      this.setAuthTimer(expiresIn / 1000);
      this.authStatusListener.next(true);

      //we need to navigate to dashboard
      //this.router.navigate(['/', 'home']);
    } else {
      this.logout();
    }
  }

  logout() {
    this.token = '';
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    this.userId = '';
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(['/']);
  }

  private setAuthTimer(duration: number) {
    this.tokenTimer = setTimeout(() => {
      this.logout()
    }, (duration * 1000));
  }

  private saveAuthData(token: string, expirationDate: Date, userId: string, user: any) {
    localStorage.setItem('token', token);
    localStorage.setItem('expiration', expirationDate.toISOString());
    localStorage.setItem('userId', userId);
    localStorage.setItem('userName', user.full_name);
    localStorage.setItem('userPhoto', user.photo_path);
    localStorage.setItem('accountId', user.account_id);
    localStorage.setItem('dateFormat', user.date_format ? user.date_format : 'DD-MM-YYYY');
    localStorage.setItem('timeFormat', user.time_format ? user.time_format : 'hh:mm:ss A');

    this.sharedService.setUserId(userId);
    this.sharedService.setUsername(user.full_name);
    this.sharedService.setAccountId(user.account_id);
  }

  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('expiration');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('userPhoto');
    localStorage.removeItem('accountId');
    localStorage.removeItem('dateFormat');
    localStorage.removeItem('timeFormat');
  }

  private getAuthData() {
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expiration');
    const userId = localStorage.getItem('userId');
    const userName = localStorage.getItem('userName');
    const userPhoto = localStorage.getItem('userPhoto');
    const accountId = localStorage.getItem('accountId');
    // const dateFormat = localStorage.getItem('dateFormat');
    // const timeFormat = localStorage.getItem('timeFormat');

    if (!token || !expirationDate) {
      return false;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      userId: userId,
      userName: userName,
      userPhoto: userPhoto,
      accountId: accountId
    }
  }
}
