import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService } from './pages/auth/auth.service';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent {
  authService: AuthService = inject(AuthService);

  constructor() {
    this.authService.autoAuthUser();
  }
}
