import { HttpHeaders } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { APIConstants } from 'src/app/core/constants/APIConstants';
import { MasterService } from 'src/app/core/services/master.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private active_path = signal<string>('');
  private user_id = signal<string>('');
  private user_name = signal<string>('');
  private account_id = signal<string>('');
  private user_photo = signal<string>('');

  constructor(
    private master: MasterService
  ) { }

  setActivePath(path: string) {
    this.active_path.update(() => path);
  }

  getActivePath() {
    return this.active_path();
  }

  setUsername(username: string) {
    this.user_name.update(() => username);
  }

  getUsername() {
    return this.user_name();
  }

  setUserPhoto(userphoto: string) {
    this.user_photo.update(() => userphoto);
  }

  getUserPhoto() {
    return this.user_photo();
  }

  setAccountId(accountid: string) {
    this.account_id.update(() => accountid);
  }

  getAccountId() {
    return this.account_id();
  }

  savedocumentattachment(myfile: File) {
    const postData = new FormData();
    postData.append("myfile", myfile, "document");
    return this.master.post(environment.base_url + APIConstants.shared.imageUploader, postData, {
      headers: new HttpHeaders().set("Authorization", "bearer " + ''),
    });
  }

  getCombinedMastersLists(data: any) {
    return this.master.post(environment.base_url + APIConstants.shared.getCombinedMasters, data);
  }

  setUserId(userid: string) {
    this.user_id.update(() => userid);
  }

  getUserId() {
    return this.user_id();
  }

  getUTCtoCurrentDate(utctime: any) {
    return moment.utc(utctime).tz('Asia/Kolkata').format(String(localStorage.getItem('dateFormat')));
  }

  getUTCtoCurrentDateTime(utctime: any) {
    return moment.utc(utctime).tz('Asia/Kolkata').format(localStorage.getItem('dateFormat') + ' ' + localStorage.getItem('timeFormat'));
  }

  getCurrentSystemTime() {
    return moment().format(localStorage.getItem('dateFormat') + ' ' + localStorage.getItem('timeFormat'));
  }

  getDashboardData(data: any) {
    return this.master.post(environment.base_url + APIConstants.shared.getDashboardData, data);
}
}
