<div *ngIf="config$ | async as setting" class="config-panel">
  <h2 class="headline mb-4">
    <mat-icon class="mr-3" svgIcon="mat:settings"></mat-icon>
    <span>Setup</span>
  </h2>

  <!-- <div class="section">
    <div class="section-content">
      <h5 class="subheading">Position</h5>

      <mat-radio-group
        (change)="toolbarPositionChange($event)"
        [value]="setting.toolbar.fixed ? 'fixed' : 'static'"
        class="flex flex-col gap-3">
        <mat-radio-button value="fixed">Fixed</mat-radio-button>
        <mat-radio-button value="static">Static</mat-radio-button>
      </mat-radio-group>
    </div>
  </div> -->

  <div class="section">

    <div class="section-content">

      <h5 class="subheading">News Scroll</h5>

      <mat-radio-group
        (change)="footerPositionChange($event)"
        [value]="setting.footer.fixed ? 'fixed' : 'static'"
        class="flex flex-col gap-3">
        <mat-radio-button value="fixed">Center</mat-radio-button>
        <mat-radio-button value="static">Left</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
