import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(
    private http: HttpClient
  ) { }

  get(url: string): Observable<any> {
    return this.http.get(url);
  }

  post(url: string, data: any, options?: any): Observable<any> {
    return this.http.post(url, data, options);
  }

  delete(url: string): Observable<any> {
    return this.http.delete(url);
  }
}

//SAMPLE API CALL FROM COMPONENTS

// this.authService.registerUser(data).subscribe({
//   next: (res: any) => {
//     console.log(res)
//   },
//   error: (err: any) => {
//     console.log(err);
//   },
//   complete: () => {
//     //we will enter here after successful response and processing
    
//   }
// });
