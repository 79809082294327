import { Injectable } from '@angular/core';
import { MasterService } from './master.service';
import { environment } from 'src/environments/environment';
import { APIConstants } from '../constants/APIConstants';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private master: MasterService
  ) { }

  getUserInfo(user_id: string) {
    return this.master.get(environment.base_url + APIConstants.user.getUserInfo + user_id);
  }

  saveUser(data: any) {
    if (data._id === '') {
      return this.master.post(environment.base_url + APIConstants.user.createUser, data);
    } else {
      return this.master.post(environment.base_url + APIConstants.user.updateUser, data);
    }
  }

  updateUser(data: any) {
    return this.master.post(environment.base_url + APIConstants.user.updateUser, data);
  }

  changePassword(data: any) {
    return this.master.post(environment.base_url + APIConstants.user.changePassword, data);
  }

  getSchoolStaff() {
    return this.master.get(environment.base_url + APIConstants.user.getSchoolStaff);
  }

  deleteStaff(staff_id: string) {
    return this.master.delete(environment.base_url + APIConstants.user.deleteStaff + staff_id);
  }

  saveStaffMigrationImport(data: any) {
    return this.master.post(environment.base_url + APIConstants.user.saveStaffMigrationImport, data);
  }

  updateStaffStatus(data: any) {
    return this.master.post(environment.base_url + APIConstants.user.updateStaffStatus, data);
  }
}
